import { Report } from '../types';


// region Common fields
const commonFields: Record<string, Report.FieldInfo> = {
    'id': { title: 'ID' },
    'report': { title: 'Отчет' },
    'order_number': { title: '№' },
    'debug_info': { title: 'Информация для отладки' }
};
Object.freeze(commonFields);
Object.getOwnPropertyNames(commonFields).forEach(key => {
    Object.freeze(commonFields[key]);
});
const prepare = (formFields: Record<string, Report.FieldInfo>): Record<string, Report.FieldInfo> => {
    const result: Record<string, Report.FieldInfo> = {};
    Object.getOwnPropertyNames(formFields).forEach(key => {
        const newKey = `l__${key}`;
        result[newKey] = formFields[key];
    });
    Object.getOwnPropertyNames(commonFields).forEach(key => {
        result[key] = commonFields[key];
    });
    return result;
};
// endregion


// region Additional Payment Columns - EAD
// noinspection SpellCheckingInspection
const eadColumns: Record<string, Report.FieldInfo> = {
    'al_by_pprk_1193': { title: 'Прочие надбавки, утвержденные Постановлением Правительства РК от от 31 декабря 2015 № 1193 (сумма)' },
    'al_by_pprk_646': { title: 'Надбавки, предусмотренные ППРК 646 ДСП (сумма)' },
    'al_car_with_trailer': { title: 'Надбавка за работу на автомобиле с прицепами (сумма)' },
    'AL_GRADE': { title: 'Надбавка за классность (% от БДО) (сумма)' },
    'AL_GRADE_QUALIFICATION': { title: 'Надбавка за классную квалификацию (% от БДО)' },
    'AL_GRADE_QUALIFICATION_MVD': { title: 'Надбавка за классную квалификацию (% от ДО)' },
    'AL_HONORARY_TITLE': { title: 'Надбавка за почетное звание (% от БДО)' },
    'al_hqsrrmscl': { title: 'Надбавка за высокое качество научных исследований и отчетных материалов, представляемых руководству страны (сумма)' },
    'al_law_research': { title: 'Надбавка за проведение фундаментальных и прикладных исследований в области права (сумма)' },
    'al_prof_excellence': { title: 'Надбавка за профессиональное мастерство (сумма)' },
    'al_seniority': { title: 'Надбавка за выслугу лет (сумма)' },
    'al_special_work_conditions': { title: 'Надбавка за особые условия труда (% к ДО)' },
    'AL_SPECIAL_WORK_CONDITIONS__BDO': { title: 'Надбавка за особые условия труда (% к БДО)' },
    'AL_SPORT_TITLE': { title: 'Надбавка за спортивное звание (% от БДО)' },
    'AL_SPECIAL_COND_OF_SERV': { title: 'Надбавка за особые условия прохождения службы (% к ДО)' },
    'AL_SPECIAL_WORK_CONDITIONS_MVD': { title: 'Надбавка за особые условия труда МВД (% к ДО)' },
    'AL_TEAM_LEADERSHIP': { title: 'Надбавка за руководство бригадой (% от БДО) (сумма)' },
    'al_troop': { title: 'Надбавка за работу в Вооруженных Силах, других войсках и воинских формированиях, в системе специальных государственных, правоохранительных органов, государственной противопожарной службы (сумма)' },
    'ap_emergency_standby': { title: 'Надбавка за нахождение в режиме ожидания и постоянной готовности к выезду на аварийно-спасательные и неотложные работы (сумма)' },

    'ap_academic_degree': { title: 'Доплата за ученую степень (коэффициент к МРП)' },
    'AP_CHAMPION_PREPARE': { title: 'Доплата за подготовку чемпионов и призеров спортивных соревнований (% от ДО)' },
    'AP_CHECK_NOTE_AND_WORKS': { title: 'Доплата за проверку тетрадей и письменных работ (% от БДО)' },
    'AP_CLASS_GROUP_LEADERSHIP': { title: 'Доплата за классное руководство (руководство группой) (% от БДО)' },
    'ap_class_rank_by_dict': { title: 'Доплата за классный чин (по справочнику) (сумма)' },
    'AP_CLASS_RANK_SALARY_RATE': { title: 'Доплата за классный чин (% от ДО)' },
    'AP_CLASSROOM_MANAGEMENT': { title: 'Доплата за заведование учебными кабинетами (лабораториями, мастерскими, учебно-консультативными пунктами) (% от БДО)' },
    'ap_comb_pos_temp_absent': { title: 'Доплата за совмещение должностей (расширение зоны обслуживания) и выполнение обязанностей временно отсутствующего работника (сумма)' },
    'AP_COMBINING_POSITIONS': { title: 'Доплата за совмещение должностей (расширение зоны обслуживания) (% от ДО)' },
    'AP_COMBINING_POSITIONS__BDO': { title: 'Доплата за совмещение должностей (расширение зоны обслуживания) (% от БДО)' },
    'AP_DEPARTMENT_MANAGEMENT': { title: 'Доплата за заведование отделением (кабинетом) (% от БДО)' },
    'AP_ECO_DISASTER_LIVING_MRP': { title: 'Доплата за проживание в зонах экологического бедствия (коэффициент к МРП)' },
    'AP_ECO_DISASTER_WORKING_SALARY_RATE': { title: 'Доплата за работу в зонах экологического бедствия (% от ДО)' },
    'AP_EDU_PROCESS_PROVISION': { title: 'Доплата за непосредственное обеспечение высококачественного учебно-тренировочного процесса (% от ДО)' },
    'ap_fmaamcfmdapupm': { title: 'Зафиксированное денежное довольствие и денежное компенсация на содержание жилища и оплату коммунальных услуг в месяц (сумма)' },
    'AP_HARD_DANGER_WORK': { title: 'Доплата за работу на тяжелых (особо тяжелых) физических работах и работах с вредными (особо вредными) и опасными (особо опасными) условиями труда (в тенге)' },
    'AP_HARD_DANGER_WORK__BDO': { title: 'Доплата за работу на тяжелых (особо тяжелых) физических работах и работах с вредными (особо вредными) и опасными (особо опасными) условиями труда (% от БДО)' },
    'AP_HARD_DANGER_WORK__SR': { title: 'Доплата за работу на тяжелых (особо тяжелых) физических работах и работах с вредными (особо вредными) и опасными (особо опасными) условиями труда (% от ДО)' },
    'AP_HOLIDAY_WORK': { title: 'Доплата за работу в праздничные и выходные дни (в тенге)' },
    'AP_IN_DEPTH_TEACH': { title: 'Доплата за углубленное преподавание отдельных предметов профильного направления (% от БДО)' },
    'ap_medical_care_in_area': { title: 'Доплата за оказание медицинской помощи в условиях территориального участка (сумма)' },
    'ap_military_rank_salary': { title: 'Оклад по воинским званиям (сумма)' },
    'ap_night_work': { title: 'Доплата за работу в ночное время (в тенге)' },
    'ap_org_ind_training': { title: 'Доплата за организацию производственного обучения (коэффициент к БДО)' },
    'AP_OVERTIME_PAY': { title: 'Доплата за сверхурочную работу (в тенге)' },
    'AP_QUALIFICATION_LEVEL': { title: 'Доплата за квалификационный уровень (% от ДО)' },
    'ap_pprk_1193': { title: 'Прочие доплаты, утвержденные Постановлением Правительства РК от от 31 декабря 2015 № 1193 (сумма)' },
    'ap_prof_excellence': { title: 'Доплата за профессиональное мастерство (сумма)' },
    'AP_PSYCHO_EMO_PHYSICAL': { title: 'Доплата за психоэмоциональные и физические нагрузки (сумма)' },
    'AP_PSYCHO_EMO_PHYSICAL_SR': { title: 'Доплата за психоэмоциональные и физические нагрузки (сумма)' },
    'AP_RAD_RISK_LIVING_MRP': { title: 'Доплата за проживание на территориях радиационного риска (коэффициент к МРП)' },
    'AP_RAD_RISK_WORKING_MRP': { title: 'Доплата за работу на территориях радиационного риска (коэффициент к МРП)' },
    'AP_HARMFUL_DANGER_WORK': { title: ' Доплата за работу с вредными (особо вредными) и опасными (особо опасными) условиями труда (% от БДО)' },
    'ap_special_cond_of_serv': { title: 'Доплата за особые условия прохождения службы (сумма)' },
    'ap_special_title': { title: 'Доплата за специальные звания (сумма)' },
    'ap_special_title_by_dict': { title: 'Доплата за специальные звания (по справочнику) (сумма)' },
    'AP_SPECIAL_WORK_CONDITIONS': { title: 'Доплата за особые условия труда (% от БДО)' },
    'AP_SPECIAL_WORK_CONDITIONS_OS': { title: 'Доплата за особые условия труда (% к ДО)' },
    'AP_SPECIAL_WORK_CONDITIONS_TG': { title: 'Доплата за особые условия труда (в тенге)' },
    'AP_SPECIAL_CONDITIONS': { title: 'Доплата за особые условия (% от БДО)' },
    'AP_SPECIAL_CONDITIONS_TG': { title: 'Доплата за особые условия (в тенге)' },
    'AP_STATUS_MAIN': { title: 'Доплата за статус "Главная" (% от БДО)' },
    'AP_STATUS_SENIOR': { title: 'Доплата за статус "Старший" (% от БДО)' },
    'AP_SPECIAL_CONDITIONS_MVD_BDO': { title: 'Доплаты за специальные звания (% от БДО)' },
    'AP_TEMP_ABSENT_DUTY': { title: 'Доплата за выполнение обязанностей временно отсутствующего работника (% от ДО)' },
    'AP_TEMP_ABSENT_DUTY__SUM': { title: 'Доплата за выполнение обязанностей временно отсутствующего работника (в тенге)' },
    'pm_rural': { title: 'Повышение за работу в сельской местности (сумма)' }
};
Object.freeze(eadColumns);
const additionalPaymentColumns: Record<string, Report.FieldInfo> = {
    'c__ap_total': { title: 'Сумма доплат', subtitle: 'тыс. тг.' },
    'c__al_total': { title: 'Сумма надбавок', subtitle: 'тыс. тг.' }
};
Object.getOwnPropertyNames(eadColumns).forEach(key => {
    const preparedKey = key.toLowerCase();
    const title = eadColumns[key].title;

    additionalPaymentColumns[`c__ead__${preparedKey}`] = { title: `${title} (сумма)`, subtitle: 'тыс. тг.' };
    Object.freeze(additionalPaymentColumns[`c__ead__${preparedKey}`]);

    additionalPaymentColumns[`c__ead_cnt__${preparedKey}`] = { title: `${title} (кол-во сотрудников)`, subtitle: 'ед.' };
    Object.freeze(additionalPaymentColumns[`c__ead_cnt__${preparedKey}`]);
});

// region AP_ECO_DISASTER_LIVING_MRP - Доплата за проживание в зонах экологического бедствия (коэффициент к МРП)
additionalPaymentColumns['c__ead_cnt__dv_AP_ECO_DISASTER_LIVING_MRP__1_5'.toLowerCase()] = { title: 'Доплата за проживание в зонах экологического бедствия (коэффициент к МРП) - в том числе по коэффициенту 1.5', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_ECO_DISASTER_LIVING_MRP__1_3'.toLowerCase()] = { title: 'Доплата за проживание в зонах экологического бедствия (коэффициент к МРП) - в том числе по коэффициенту 1.3', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_ECO_DISASTER_LIVING_MRP__1_2'.toLowerCase()] = { title: 'Доплата за проживание в зонах экологического бедствия (коэффициент к МРП) - в том числе по коэффициенту 1.2', subtitle: 'ед.' };
// endregion

// region AP_RAD_RISK_LIVING_MRP - Доплата за проживание на территориях радиационного риска (коэффициент к МРП)
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_LIVING_MRP__2_0'.toLowerCase()] = { title: 'Доплата за проживание на территориях радиационного риска (коэффициент к МРП) - в том числе 2 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_LIVING_MRP__1_75'.toLowerCase()] = { title: 'Доплата за проживание на территориях радиационного риска (коэффициент к МРП) - в том числе 1.75 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_LIVING_MRP__1_5'.toLowerCase()] = { title: 'Доплата за проживание на территориях радиационного риска (коэффициент к МРП) - в том числе 1.5 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_LIVING_MRP__1_25'.toLowerCase()] = { title: 'Доплата за проживание на территориях радиационного риска (коэффициент к МРП) - в том числе 1.25 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_LIVING_MRP__1_0'.toLowerCase()] = { title: 'Доплата за проживание на территориях радиационного риска (коэффициент к МРП) - в том числе 1 месячный расчетный показатель', subtitle: 'ед.' };
// endregion

// region AP_RAD_RISK_WORKING_MRP - Доплата за работу на территориях радиационного риска (коэффициент к МРП)
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_WORKING_MRP__2_0'.toLowerCase()] = { title: 'Доплата за работу на территориях радиационного риска (коэффициент к МРП) - в том числе 2 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_WORKING_MRP__1_75'.toLowerCase()] = { title: 'Доплата за работу на территориях радиационного риска (коэффициент к МРП) - в том числе 1.75 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_WORKING_MRP__1_5'.toLowerCase()] = { title: 'Доплата за работу на территориях радиационного риска (коэффициент к МРП) - в том числе 1.5 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_WORKING_MRP__1_25'.toLowerCase()] = { title: 'Доплата за работу на территориях радиационного риска (коэффициент к МРП) - в том числе 1.25 месячный расчетный показатель', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_RAD_RISK_WORKING_MRP__1_0'.toLowerCase()] = { title: 'Доплата за работу на территориях радиационного риска (коэффициент к МРП) - в том числе 1 месячный расчетный показатель', subtitle: 'ед.' };
// endregion

// region AP_ECO_DISASTER_WORKING_SALARY_RATE - Доплата за работу в зонах экологического бедствия (% от ДО)
additionalPaymentColumns['c__ead_cnt__dv_AP_ECO_DISASTER_WORKING_SALARY_RATE__0_5'.toLowerCase()] = { title: 'Доплата за работу в зонах экологического бедствия (% от ДО) - в том числе по 50% ДО', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_ECO_DISASTER_WORKING_SALARY_RATE__0_3'.toLowerCase()] = { title: 'Доплата за работу в зонах экологического бедствия (% от ДО) - в том числе по 30% ДО', subtitle: 'ед.' };
additionalPaymentColumns['c__ead_cnt__dv_AP_ECO_DISASTER_WORKING_SALARY_RATE__0_2'.toLowerCase()] = { title: 'Доплата за работу в зонах экологического бедствия (% от ДО) - в том числе по 20% ДО', subtitle: 'ед.' };
// endregion

Object.freeze(additionalPaymentColumns);
const insertAdditionalPaymentColumns = (formFields: Record<string, Report.FieldInfo>): Record<string, Report.FieldInfo> => {
    Object.getOwnPropertyNames(additionalPaymentColumns).forEach(key => {
        formFields[key] = additionalPaymentColumns[key];
    });
    return formFields;
};
// endregion


// region Func block count by dict_civil_position_rate
const funcBlocCountByCivilPositionRate: Record<string, Report.FieldInfo> = {
    'func_block_count__a__0__3': { title: 'Количество штатных единиц: Управленческий персонал (от 0 до 3)', subtitle: 'ед.' },
    'func_block_count__a__3__6': { title: 'Количество штатных единиц: Управленческий персонал (от 3 до 6)', subtitle: 'ед.' },
    'func_block_count__a__6__9': { title: 'Количество штатных единиц: Управленческий персонал (от 6 до 9)', subtitle: 'ед.' },
    'func_block_count__a__9__12': { title: 'Количество штатных единиц: Управленческий персонал (от 9 до 12)', subtitle: 'ед.' },
    'func_block_count__a__12__16': { title: 'Количество штатных единиц: Управленческий персонал (от 12 до 16)', subtitle: 'ед.' },
    'func_block_count__a__16__20': { title: 'Количество штатных единиц: Управленческий персонал (от 16 до 20)', subtitle: 'ед.' },
    'func_block_count__a__20__25': { title: 'Количество штатных единиц: Управленческий персонал (от 20 до 25)', subtitle: 'ед.' },
    'func_block_count__a__25__99': { title: 'Количество штатных единиц: Управленческий персонал (свыше 25)', subtitle: 'ед.' },
    'func_block_count__not_a__0__1': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 0 до 1)', subtitle: 'ед.' },
    'func_block_count__not_a__1__2': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 1 до 2)', subtitle: 'ед.' },
    'func_block_count__not_a__2__3': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 2 до 3)', subtitle: 'ед.' },
    'func_block_count__not_a__3__5': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 3 до 5)', subtitle: 'ед.' },
    'func_block_count__not_a__5__7': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 5 до 7)', subtitle: 'ед.' },
    'func_block_count__not_a__7__10': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 7 до 10)', subtitle: 'ед.' },
    'func_block_count__not_a__10__13': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 10 до 13)', subtitle: 'ед.' },
    'func_block_count__not_a__13__16': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 13 до 16)', subtitle: 'ед.' },
    'func_block_count__not_a__16__20': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 16 до 20)', subtitle: 'ед.' },
    'func_block_count__not_a__20__25': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (от 20 до 25)', subtitle: 'ед.' },
    'func_block_count__not_a__25__99': { title: 'Количество штатных единиц: Основной, административный и вспомогательный персонал (свыше 25)', subtitle: 'ед.' }
};
Object.getOwnPropertyNames(funcBlocCountByCivilPositionRate).forEach(key => {
    Object.freeze(funcBlocCountByCivilPositionRate[key]);
});
Object.freeze(funcBlocCountByCivilPositionRate);
const addFuncBlocCountByCivilPositionRate = (fields: Record<string, Report.FieldInfo>): Record<string, Report.FieldInfo> => {
    Object.getOwnPropertyNames(funcBlocCountByCivilPositionRate).forEach(key => {
        fields['l__' + key] = funcBlocCountByCivilPositionRate[key];
    });
    return fields;
};
// endregion


const formsFields: Partial<Record<Report.FormKey, Record<string, Report.FieldInfo>>> = {};

// region Forms 01-***
formsFields.F_01_111 = prepare({
    'position_category': { title: 'Категория должностей' },
    'func_block_and_pos_level': { title: 'Функциональный блок и уровень должности' },
    'position_names': { title: 'Наименования должностей' },
    'experience_in_years': { title: 'Стаж государственной службы в годах' },
    'cnt': { title: 'Количество штатных единиц', subtitle: 'ед.' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' },
    'fact_expense_sum': { title: 'Фактическая сумма расходов', subtitle: 'тыс. тг.' },
    'allowance_total': { title: 'Сумма надбавок', subtitle: 'тыс. тг.' },
    'add_payment_total': { title: 'Сумма доплат', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_01_111);

formsFields.F_01_112 = prepare({
    'position_category': { title: 'Категория должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'salary_month_increase': { title: 'Увеличение заработной платы', subtitle: 'тыс. тг.' },
    'premium': { title: 'Сумма двух должностных окладов в год для премирования', subtitle: 'тыс. тг.' },
    'one_time_payment': { title: 'Единовременные денежные выплаты и надбавки к должностным окладам работникам органов государственных доходов и работникам структурного подразделения Министерств по инвестициям и развитию и юстиции РК', subtitle: 'тыс. тг.' },
    'total': { title: 'Итого', subtitle: 'тыс. тг.' }
});

formsFields.F_01_113 = prepare({
    'position_category': { title: 'Категория должности' },
    'func_block_and_pos_level': { title: 'Функциональный блок и уровень должности' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'bf_wellness': { title: 'Пособие на оздоровление государственных и гражданских служащих', subtitle: 'тыс. тг.' },
    'bf_eco_disaster_health': { title: 'Пособие на оздоровление работникам, проживающим в зонах экологического бедствия', subtitle: 'тыс. тг.' },
    'bf_year_sum': { title: 'Сумма пособий на оздоровление в год', subtitle: 'тыс. тг.' },
    'bf_movement_lifting': { title: 'Подъемное пособие при служебном перемещении', subtitle: 'тыс. тг.' },
    'bf_fired': { title: 'Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий, а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы', subtitle: 'тыс. тг.' },
    'cm_harmful_hazardous_conditions': { title: 'Компенсация за вредные и опасные условия труда', subtitle: 'тыс. тг.' },
    'cm_special_work_conditions': { title: 'Компенсация за особые условия труда', subtitle: 'тыс. тг.' },
    'total': { title: 'Итого по специфике 113', subtitle: 'тыс. тг.' }
});

formsFields.F_01_116 = prepare({
    'count': { title: 'Количество работников', subtitle: 'ед.' },
    'salary_month_sum': { title: 'Ежемесячный доход работников', subtitle: 'тыс. тг.' },
    'pension_contribution_percent': { title: 'Размер обязательного пенсионного взноса работодателя', subtitle: '%' },
    'pension_contribution_month_sum': { title: 'Сумма взноса в месяц', subtitle: 'тыс. тг.' },
    'pension_contribution_year_sum': { title: 'Сумма взноса в год', subtitle: 'тыс. тг.' }
});

formsFields.F_01_121 = prepare({
    'name': { title: 'Наименование' },
    'count': { title: 'Количество', subtitle: 'ед.' },
    'salary_sum': { title: 'Налогооблагаемый фонд оплаты труда', subtitle: 'тыс. тг.' },
    'social_tax_rate': { title: 'Ставка социального налога', subtitle: '%' },
    'social_tax_sum': { title: 'Сумма налога в год', subtitle: 'тыс. тг.' }
});

// noinspection SpellCheckingInspection
formsFields.F_01_122 = prepare({
    'nfot': { title: 'Налогооблагаемый фонд оплаты труда', subtitle: 'тыс. тг.' },
    'deduction_percent': { title: 'Ставка социальных отчислений налога', subtitle: '%' },
    'deduction_sum': { title: 'Сумма социальных отчислений налога', subtitle: 'тыс. тг.' }
});

formsFields.F_01_124 = prepare({
    'salary_sum': { title: 'Объект исчисления отчислений', subtitle: 'тыс. тг.' },
    'deduction_percent': { title: 'Размер отчислений', subtitle: '%' },
    'deduction_sum': { title: 'Сумма отчислений на обязательное социальное медицинское страхование в год', subtitle: 'тыс. тг.' }
});

formsFields.F_01_131 = prepare({
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'experience_in_years': { title: 'Стаж государственной службы в годах' },
    'rates': { title: 'Коэффициенты' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_01_131);
addFuncBlocCountByCivilPositionRate(formsFields.F_01_131);

formsFields.F_01_132 = prepare({
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'experience_in_years': { title: 'Стаж государственной службы в годах' },
    'rates': { title: 'Коэффициенты' },
    'salary_month_sum_without_rural_increase': { title: 'Сумма должностных окладов без повышения сельской местности в месяц', subtitle: 'тыс. тг.' },
    'salary_month_sum_with_rural_increase': { title: 'Сумма должностного оклада с  повышения сельской местности в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_01_132);
addFuncBlocCountByCivilPositionRate(formsFields.F_01_132);


formsFields.F_01_135 = prepare({
    'sn__salary': { title: 'Соц. налог: Налогооблагаемый фонд оплаты труда', subtitle: 'тыс. тг.' },
    'sn__rate': { title: 'Соц. налог: Ставка социального налога', subtitle: '%' },
    'sn__sum': { title: 'Соц. налог: Сумма налога в год', subtitle: 'тыс. тг.' },
    'so_salary': { title: 'Соц. отчисления в гос. фонд соц. страхования: Налогооблагаемый фонд оплаты труда', subtitle: 'тыс. тг.' },
    'so_rate': { title: 'Соц. отчисления в гос. фонд соц. страхования: Ставка социальных отчислений', subtitle: '%' },
    'so_sum': { title: 'Соц. отчисления в гос. фонд соц. страхования: Сумма социальных отчислений в год', subtitle: 'тыс. тг.' },
    'osms_salary': { title: 'Обяз. соц. мед. страхование: Объект исчисления отчислений', subtitle: 'тыс. тг.' },
    'osms_rate': { title: 'Обяз. соц. мед. страхование: Размер отчислений', subtitle: '%' },
    'osms_sum': { title: 'Обяз. соц. мед. страхование: Сумма отчислений на обязательное социальное медицинское страхование в год', subtitle: 'тыс. тг.' },
    'total': { title: 'Всего', subtitle: 'тыс. тг.' }
});
// endregion


// region Forms 02-***
formsFields.F_02_111 = prepare({
    'cnt': { title: 'Количество штатных единиц', subtitle: 'ед.' },
    'position_level': { title: 'Уровень должности' },
    'position_names': { title: 'Наименования должностей' },
    'experience_in_years': { title: 'Стаж государственной службы в годах' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' },
    'fact_expense_sum': { title: 'Фактическая сумма расходов', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_02_111);

formsFields.F_02_114 = prepare({
    'cnt': { title: 'Количество работников' },
    'salary_month_sum': { title: 'Ежемесячный доход работников', subtitle: 'тыс. тг.' },
    'pension_contribution_percent': { title: 'Размер обязательного пенсионного взноса работодателя', subtitle: '%' },
    'pension_contribution_month_sum': { title: 'Сумма взноса в месяц', subtitle: 'тыс. тг.' },
    'pension_contribution_year_sum': { title: 'Сумма взноса в год', subtitle: 'тыс. тг.' }
});

formsFields.F_02_131 = prepare({
    'cnt': { title: 'Количество штатных единиц', subtitle: 'ед.' },
    'rank': { title: 'Квалификационный разряд' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_02_131);
// endregion


formsFields.F_04_111 = prepare({
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_04_111);
addFuncBlocCountByCivilPositionRate(formsFields.F_04_111);


formsFields.F_05_111 = prepare({
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_05_111);
addFuncBlocCountByCivilPositionRate(formsFields.F_05_111);


formsFields.F_06_111 = prepare({
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_06_111);
addFuncBlocCountByCivilPositionRate(formsFields.F_06_111);


formsFields.F_07_111 = prepare({
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_07_111);
addFuncBlocCountByCivilPositionRate(formsFields.F_07_111);


formsFields.F_08_111 = prepare({
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_08_111);
addFuncBlocCountByCivilPositionRate(formsFields.F_08_111);


formsFields.F_09_111 = prepare({
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_09_111);
addFuncBlocCountByCivilPositionRate(formsFields.F_09_111);


formsFields.F_10_111 = prepare({
    'cnt': { title: 'Количество штатных единиц: Всего', subtitle: 'ед.' },
    'func_block_and_echelon': { title: 'Функциональный блок и звено должности' },
    'position_names': { title: 'Наименования должностей' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_10_111);
addFuncBlocCountByCivilPositionRate(formsFields.F_10_111);

formsFields.F_11_111 = prepare({
    'cnt': { title: 'Количество штатных единиц', subtitle: 'ед.' },
    'rank': { title: 'Квалификационный разряд' },
    'salary_month_sum': { title: 'Сумма должностных окладов в месяц', subtitle: 'тыс. тг.' },
    'add_pay_month_sum': { title: 'Сумма надбавок в месяц', subtitle: 'тыс. тг.' },
    'total_salary_month_sum': { title: 'Итого основной заработной платы в месяц', subtitle: 'тыс. тг.' },
    'total_salary_year_sum': { title: 'Итого основной заработной платы в год', subtitle: 'тыс. тг.' }
});
insertAdditionalPaymentColumns(formsFields.F_11_111);


export default formsFields;